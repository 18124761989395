import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './WhatWeDo.module.css'
import Layout from '../components/layout'
import TRAINING from '../components/TRAINING'
import HeroLayout from '../components/heroLayout'
import { Link, graphql } from 'gatsby'

class WhatWeDoTRAINING extends React.Component
{
    render()
    {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const [layoutHeroImage] = get(this, 'props.data.allContentfulLayoutHeroImage.edges')

        return (
        <Layout location={this.props.location}>
            <div style={{ background: '#fff' }}>
                <Helmet title={siteTitle} />
                <HeroLayout data={layoutHeroImage.node} />
                <div className="wrapper">
                    <h2 className="section-headline">TRAINING</h2>                                  
                    <TRAINING/>                    
                </div>
            </div>
        </Layout>
        )
    }
  }
  
  export default WhatWeDoTRAINING

  export const trainingQuery = graphql`
  query TrainingQuery {
    allContentfulLayoutHeroImage(filter: {title: {regex: "/Training 3/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
  }  
`