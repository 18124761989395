import React from 'react'
import styles from './TRAINING.module.css'

export default () => 
(
  <div>
    <p>
        Training opportunities are presented on a regular basis. Quarterly workshops are held to equip Bible club leaders and people in
        children’s work. Practical ideas are shared on how to teach the truths of the Word of God on a child’s level through Bible lessons,
        games, and other creative ideas. Volunteers can then present this weekly in their Bible clubs or children’s church.
    </p>
    <p>
        We also offer an assistant training program for pre-primary school teachers in order to help them to establish a Bible education
        curriculum in their schools. This includes activities that focus on preparing children for school readiness, through using the book
        “The colours tell a story” by KidsXpress. This program has been established in a number of pre-primary schools in Xhosa and
        Afrikaans communities.
    </p>
    <p>
        Informal training opportunities, like parent workshops and small group training sessions, are organised on a regular basis.
    </p>
    <u><strong>Some of the Xhosa teachers who attended the training said:</strong></u>
    <figure class="quote">
    <blockquote>
        “It helped us to have a better understanding of the message of the Bible, of what happened many years ago and after Jesus
        was born. The ideas are so useful because they integrate general things that are happening in our daily lives. Our children
        have grown in knowing God and how the Bible teaches us how to live our lives. They learnt about how special they are to
        God and how to talk to God. The rhymes, activities and games in the book helped them to grow in various skills and have
        improved their language.”
    </blockquote>
    <figcaption>
        &mdash; Noncedo Mtya, Ncumisa Ntendiyo, Ntombenkosi Yoywana, Fezeka Mtengwane (Teachers at Umniqophiso Pre-Primary, Lwandle, Strand)  </figcaption>
    </figure>
  </div>
)
